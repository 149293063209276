
.main-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0 20px 20px 20px;
    .main-wrapper-table{
        display: flex;
        flex-direction: column;
        height: 100%;
        .goods-data-table{
            border: 1px solid #EEEEEE;
            box-sizing: border-box;
            width: 100%;
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            .data-table-header{
                padding: 0 20px 0 20px;
                display: flex;
                background: #EEEEEE;
                height: 60px;
                line-height: 60px;
                box-sizing: border-box;
                span{
                    color: #666666;
                    font-size: 16px;
                }
                .table-header-item{
                    flex: 1;
                    width: 1%;
                    box-sizing: border-box;
                }
            }
            .data-table-body{
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                .table-body-item{
                    display: flex;
                    flex-direction: column;
                    .body-item-top, .body-item-bottom{
                        padding: 0 20px 0 20px;
                        box-sizing: border-box;
                        width: 100%;
                        display: flex;
                        border-bottom: 1px solid #EEEEEE;
                        .item-top-item, .item-bottom-item{
                            width: 1%;
                            flex: 1;
                            height:100px;
                            display: flex;
                            align-items: center;
                            i{
                                line-height: 1;
                                cursor: pointer;
                                font-size: 18px;
                                color: #7D7D7D;
                                margin-right: 4px;
                            }
                            span{
                                //flex: 1;
                                font-size: 16px;
                                color: #333;
                            }
                            img {
                                width: 80px;
                                height: 80px;
                                margin-right: 15px;
                            }
                            .a-link{
                                height: 80px;
                                display: flex;
                                align-items: center;
                                .text {
                                    flex: 1;
                                    width: 1%;
                                    display: -webkit-box;
                                    -webkit-box-orient: vertical;
                                    -webkit-line-clamp: 2;
                                    overflow: hidden;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .goods-text-table {
        .answer {
            display: flex;
            align-items: center;
            img {
                width: 80px;
                height: 80px;
                margin-right: 15px;
            }
        }
        .a-link{
            height: 80px;
            display: flex;
            align-items: center;
            .text {
                flex: 1;
                width: 1%;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
            }
        }
    }
    .top-content{
        position: relative;
        .btn-back{
            position: absolute;
            top: -12px;
            right: 20px;
        }
    }
    .dialog-footer{
        text-align: center;
        margin-top: 20px;
    }
}
